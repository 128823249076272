<script setup lang="ts">
const { tree } = useCurrentNavigation()
const { page } = useContent()
const path = computed(() => {
  if (page.value?._path) {
    return page.value?._path
  } else if (process.client) {
    return window.location.pathname
  }
})
const isTutorials = computed(() => path.value?.startsWith('/developers/tutorials'))
const isShowcases = computed(() => path.value?.startsWith('/developers/templates'))
const isDevelopers = computed(() => path.value?.startsWith('/developers/'))
</script>

<template>
  <DocsDevAside v-if="isTutorials || isShowcases" />
  <nav v-else>
    <DocsDevNavHeader v-if="isDevelopers" />
    <DocsAsideTree v-if="tree.length > 0" :links="tree" />
    <NuxtLink v-else to="/" class="flex items-center text-sm cursor-pointer u-text-gray-500 hover:u-text-gray-700 ml-4">
      <Icon name="heroicons-outline:arrow-left" class="w-4 h-4" />
      <span class="ml-2">Go back</span>
    </NuxtLink>
  </nav>
</template>
